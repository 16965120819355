<template>
  <div class="message" @click="openMessage">
    <span class="detail title">{{message.packetId}}</span>
    <span class="detail">{{message.deviceId}}</span>
    <span class="detail">{{message.userApplicationId}}</span>
    <span class="detail lastItem">{{dateString}}</span>
  </div>
</template>

<script>
export default {
  name: 'message-item',
  props: {
    message: Object
  },
  methods: {
    openMessage(e) {
        this.$router.push({name: 'user map-display'});
    }
  },
  computed: {
    dateString() {
      const vm = this;
      return this.$moment(vm.message.hiveRxTime).format("MMM Do, YYYY HH:mm");
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  padding: 0 18px 0 0;
  margin-left: 18px;
  height: 60px;
  border-bottom: 1px solid #E8EAEB;
  position: relative;
  @media(max-width: 413px) {
    height: inherit;
    padding: 6px 0;
    flex-direction: column;
    > .detail {
      margin-bottom: 6px;
    }
  }
  &:hover {
    background-color: #FAFCFC;
  }
  .detail {
    flex:1 0 0;
    display: flex;
    align-items: center;
    padding-right: 20px;

    opacity: 0.8;
    color: #111215;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 18px;

    &.title {
      opacity: 1;
      font-weight: bold;
      flex: 1;
    }
    &.lastItem {
      opacity: 1;
      flex: 1.5;
    }
  }
  .delete-btn {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    i {
      color: rgba(#000, .25);
    }
  }
  &:hover {
    .delete-btn {
      opacity: 1;
      &:hover {
        i {
          color: #111215;
        }
      }
    }
  }
}
</style>
