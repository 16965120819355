<template>
  <message-grid></message-grid>
</template>

<script>
import messageGrid from '@/components/user/messages/message-grid'
export default {
  name: 'View_Messages',
  components: {
    messageGrid
  }
}
</script>

<style lang="scss" scoped>
</style>
